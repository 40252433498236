import PagePost from '../../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { personalGetStarted as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import {
    Hero,
    SEO
} from '@brandedholdings/react-components';

import OfferForm from '../../components/OfferForm';
import { Log } from '@brandedholdings/react-components/build/utils';


class PersonalLoansGetStarted extends React.Component {
    constructor(props) {
        super(props);

        this.state = {variant: 'default'};
    }

    componentDidMount() {
        Log.toDataLayer({
            event: 'get_started'
        });
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    onFormSubmit = (e, fields) => {
        e.preventDefault();
        console.warn('FORM SUBMIT', fields);
        window.location = "/personal-loans/results/";
    };

    render() {
        const page = this.props;

        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        return (
            <PageWrapper ppc={true}>
                <SEO post={ post } />
                <div className="background background--personal">
                    <Hero
                        heading="Lending Options For Everyone"
                        subheading="Personal Loans, Credit Cards, & More!"
                        description={(<ul className="list"><li>Loans Up To $5,000</li><li>Credit Cards For All Credit Types</li><li>Compare & Find The Best Option</li></ul>)}
                        actionText={ null }
                        actionLink={ "#" }
                        heroModifiers="hero--arrow"
                        actionModifiers={ null }
                    />
                </div>


                <div className="layout">
                    <div className="layout-content text-align--center">
                        <p>Answer a few simple questions below and we’ll tailor the results to your exact needs:</p>
                        <OfferForm personal={true} />
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

PersonalLoansGetStarted.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default PersonalLoansGetStarted;
